/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import jsonLd from '../json-ld';

type Meta = {
  name: string;
  content: string;
  property?: string;
};

interface Props {
  description?: string;
  lang?: string;
  meta?: Array<Meta>;
  title?: string;
  keywords?: string;
  ogImage?: string;
  twitterImage?: string;
  pathname?: string;
  audience?: string;
}

function SEO({
  description = '',
  lang = 'ja',
  meta = [],
  title = '',
  keywords,
  ogImage,
  twitterImage,
  pathname = '/',
  audience = 'consumer',
}: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            keywords
            description
            ogImage
            twitterImage
            twitterUsername
          }
        }
      }
    `
  );

  const titlePrefix = site.siteMetadata.title;
  const titleFrags = [titlePrefix];

  if (title) {
    titleFrags.push(title);
  }

  const seo = {
    title: titleFrags.join(' | '),
    description: description || site.siteMetadata.description,
    keywords: keywords || site.siteMetadata.keywords,
    ogImage:
      ogImage || `${site.siteMetadata.siteUrl}${site.siteMetadata.ogImage}`,
    twitterImage:
      twitterImage ||
      `${site.siteMetadata.siteUrl}${site.siteMetadata.twitterImage}`,
    twitterUsername: site.siteMetadata.twitterUsername,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      meta={(
        [
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: `description`,
            content: seo.description,
          },
          {
            property: `og:title`,
            content: seo.title,
          },
          {
            property: `og:description`,
            content: seo.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: 'og:image',
            content: seo.ogImage,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            property: 'twitter:image',
            content: seo.twitterImage,
          },
          {
            name: `twitter:creator`,
            content: seo.twitterUsername,
          },
          {
            name: `twitter:title`,
            content: seo.title,
          },
          {
            name: `twitter:description`,
            content: seo.description,
          },
        ] as Array<Meta>
      ).concat(meta)}
      link={[
        { rel: 'preconnect', href: 'https://www.google-analytics.com' },
        {
          rel: 'preconnect',
          href: `https://js.${process.env.GATSBY_BASE_DOMAIN}`,
        },
      ]}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            ...jsonLd,
            description: seo.description,
            keywords: seo.keywords,
            headline: seo.title,
            url: `https://smartpay.co${pathname}`,
            audience,
          }),
        },
      ]}
    />
  );
}

export default SEO;
