import { ReactNode } from 'react';

import SEO from '../SEO';
import Header from '../Header/Header';

import './Layout.scss';

const Layout = ({
  children,
  maxWidth,
  SEOProps,
}: {
  children: ReactNode;
  maxWidth?: number;
  SEOProps?: object;
}) => {
  const containerStyle = maxWidth ? { maxWidth } : {};

  return (
    <div className="root" style={containerStyle}>
      <SEO {...SEOProps} />
      <Header />
      <div className="content-wrapper">{children}</div>
    </div>
  );
};

export default Layout;
