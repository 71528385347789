import { Link } from 'gatsby';

import logo from '../../assets/logo.svg';

import styles from './Header.module.scss';

const Header = () => {
  return (
    <div className={styles.headerWrapper}>
      <header className={styles.header}>
        <Link to="/">
          <img src={logo} alt="Smartpay Docs" height={27} />
        </Link>
      </header>
    </div>
  );
};

export default Header;
