const currentYear = new Date().getFullYear();

const jdonLd = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: 'https://docs.smartpay.co',
  image: '/logotype.svg',
  headline: 'Smartpay | Docs',
  author: '@smartpay',
  copyrightHolder: 'Smartpay Co. Ltd.',
  copyrightYear: currentYear,
  audience: 'Merchant',
  encodings: 'UTF-8',
  keywords:
    '開発者向けドキュメント, ドキュメント, docs,BNPL, 後払いサービス, 後払い, buy now pay later, 買い物, 決済, 支払い',
  description: 'Explore our guides and examples to integrate Smartpay.',
  inLanguage: 'ja',
  locationCreated: {
    address: {
      addressCountry: 'Japan',
      addressRegion: 'Tokyo',
      postalCode: '1070061',
      streetAddress: 'Aoyama Palacio Tower 11F, 3-6-7 Kita Aoyama, Minato-ku',
    },
  },
};

export default jdonLd;
